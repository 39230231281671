<template>
  <v-container fluid class="mb-16">
    <v-card height="100%">
      <v-row>
        <v-card-text style="background: white; height: 100%">
          <v-row class="ml-7" style="width: 96%">
            <v-col>
              <template>
                <v-toolbar elevation="2" style="background: #f5f5f5">
                  <v-toolbar-title class="mt-2 mb-2 ml-1">
                    Product Purchase Report
                  </v-toolbar-title>
                  <v-spacer></v-spacer>
                  <div class="pr-4 mt-5 d-flex ">
                    <div class="search g">
                        <v-text-field v-model="search_key" label="Search by product id" filled rounded   dense hide-details>
                        </v-text-field>
                    </div>
                    <div class="icon-search pr-4 pb-5 pt-2">
                        <v-btn icon style="background: red"><v-icon  @click="searchHandle">mdi-magnify</v-icon>
                        </v-btn>                                                          
                    </div>
                    
                </div>
                  <div>
                    <v-btn text @click="print" class="ml-10">
                      <v-icon large color="black">mdi-printer </v-icon>
                    </v-btn>
                  </div>
                  <v-btn @click="exportTableToCSV('daily_report.csv')" text>
                    <v-icon class="pr-5" large color="black">mdi-download </v-icon>
                  </v-btn>
                </v-toolbar>
                <div id="dataapp" class="mt-4">
                  <v-data-table
                  :headers="SellerHeader"
                  :items="specifications"
                  :search="searchseller"
                  :server-items-length="totalItems1"
                  class="elevation-1"
                  style="background: #f5f5f5 elevation-1"
                  :loading="loading"
                  loading-text="Loading... Please wait"
                  hide-default-footer
                  disable-pagination
                >
                  <template v-slot:item.actions="{ item }">
                    <v-btn
                      shaped
                      icon
                      @click="viewSpecification(item)"
                      color="green"
                      dark
                    >
                      <v-icon dark> mdi-pencil</v-icon>
                    </v-btn>
                  </template>

                  <template v-slot:item.discount="{ item }">
                    <v-btn
                      shaped
                      text
                      @click="add_discount(item)"
                      color="green"
                    >
                      Discount
                    </v-btn>
                  </template>
                </v-data-table>                 
                </div>
                  <div class="d-flex justify-content-center mt-5">
                    <div class="col-4">
                        Total {{ totalItems1 }} records
                    </div>
                    <div class="col-4">
                        <v-select dense outlined hide-details v-model="perPage1" label="Items per page" @change="handlePageSizeChange" :items="pageSizes1" >
                        </v-select>                                                
                    </div>
                    <div class="col-4">
                        <v-pagination v-model="currentPage1" :length="totalPages1" total-visible="7" @input="handlePageChange">
                        </v-pagination>
                    </div>
                  </div>
              </template>
            </v-col>
          </v-row>
        </v-card-text>
      </v-row>

     
    </v-card>
     <!-- add modal for page all section  -->

     <div class="text-center">
      <v-dialog
        v-model="dialog"
        width="500"
      >
        <template v-slot:activator="{ on, attrs }">

        </template>
  
        <v-card>
          <v-card-title class="text-h5 grey lighten-2">
            Privacy Policy
          </v-card-title>
  
          <v-card-text>
            Your request is accecpted.We will send you a Pdf file in your mail. Thanks
          </v-card-text>
          <v-divider></v-divider>
  
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              text
              @click="dialog = false"
            >
              Close
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>

    <!-- close modal  -->
   
     
  </v-container>
</template>



<script>
  export default {
    data: () => ({
      dialog: false,
      editdialog: false,
      snackbar: "",
      text: "",
      loading: true,
      search_key:null,
      perPage1: '10',
      currentPage1: 1,
      totalItems1: 0,
      totalPages1: 0,
      pageSizes1: ['10', '30', '50', '100', 'All'],
      searchseller: "",
      menu: false,
      menu2: false,
      menu3: false,
      menu4: false,
      menu5: false,
      menu6: false,
      menu7: false,
      menu8: false,
      expmenu: false,
      menufecmenu: false,
      validEditForm: false,

      discount_types: ["none", "amount", "percentage"],
      sizes: ["none", "S", "M", "L", "XL", "XXL", "XXXL"],
      select_val: ["kg", "gm", "pcs", "dozen", "liter", "ml"],
      delivery_units: ["kg", "gm"],
      warranty_types: ["none", "day", "month", "year"],
      colors: [
        "none",
        "white",
        "deep-purple",
        "indigo",
        "yellow",
        "light-blue",
        "cyan",
        "orange",
        "red",
        "teal",
        "light-green",
        "pink",
        "purple",
        "blue",
        "lime",
        "amber",
        "deep-orange",
        "green",
        "blue-grey",
        "brown",
        "grey",
        "silver",
        "black",
      ],

      SellerHeader: [
          {
          text: "Product ID",
          value: "product_id",
          sortable: false,
        },
        {
          text: "SKU",
          value: "product_sku",
          sortable: false,
        },
        //{ text: "Barcode", value: "product_barcode", sortable: false },
        { text: "Product Name", value: "product_name", sortable: false },
        { text: "Category ", value: "category", sortable: false },
        { text: "In Quantity", value: "credit_sum.credit_sum", sortable: false },
        { text: "Out Quantity", value: "credit_sum.debit_sum", sortable: false },
        { text: "Purchase Price", value: "purchase_price", sortable: false },
        { text: "Selling Price", value: "selling_price", sortable: false },
        {
          text: "DIS Amount",
          value: "discount_value",
          sortable: false,
        },
        { text: "DIS Type", value: "discount_type", sortable: false },
        { text: "", value: "discount", sortable: false },
        { text: "", value: "actions", sortable: false },
      ],

      tickets: [],
      contact: {},

      specifications: [],

      sellerPdetails: {},

      editedItem: {
        discount: {},
        point: {},
        delivery_info: {},
        price: {},
      },

      specdataobjdiscount: {
        discount: {
          discount_type: "none",
          discount_amount: 0,
          discount_start_date: "",
          discount_end_date: "",
        },
      },
      product_dist: {
        discount_type: "none",
        discount_amount: 0,
        discount_start_date: "",
        discount_end_date: "",
      },
      addDiscountDialog: false,
      valid_add_discount: false,
      menudist: false,
      menu2dist: false,
    }),

    methods: {

       
      searchHandle(event){
        this.initialize();
      },
      handlePageSizeChange(event) {
        this.perPage1 = (event);
        this.loading = true;
        this.initialize();
      },

      handlePageChange(value) {
        this.currentPage1 = value;
        this.loading = true;
        this.initialize();
        window.scrollTo(0, 0);
      },
      //This api is for demo testing with child site data
      async initialize() {
        let perPage ;
          if (this.perPage1 === 'All'){
            this.dialog= true;
        await axios.get (`productdetails/purchase_reports_csv/`).then((response)=>{
          console.log("thread is :", response);
        })
        perPage = this.totalItems1;
            // perPage = this.totalItems1;
          }
          else
          {
            perPage = this.perPage1;
          }
          this.loading = true;
        axios
          .get(`productdetails/purchase_reports/?page=${this.currentPage1}&size=${perPage}&search_key=${this.search_key}`)
          .then((response) => {
            if(response.status === 200){
              this.specifications = response.data.results
              // console.log("product details:", this.specifications);
              this.totalItems1 = response.data.count;
              this.totalPages1 = parseInt(this.totalItems1 / this.perPage1);
              this.loading = false;
            }

            // let beforeCustomization = [];
            // beforeCustomization = response.data.data;
            // beforeCustomization.forEach((element) => {
            //   element.forEach((childElement) => {
            //     this.specifications.push(childElement);
            //   });
            // });
            // this.loading = false;
          })
          .catch((err) => {
            this.loading = false;
          });
      },

      closedialog() {
        this.dialog = false;
      },

      viewSpecification(item) {
        let routeData = this.$router.resolve({
          name: `/dashboard/pages/specification/${item.product_id}`,
        });
        routeData.href = `/dashboard/pages/specification/?id=${item.product_id}&unit=${item.unit}`;
        window.open(routeData.href, "_blank");
        // console.log("ITEM ", item);
        // axios
        //   .get(`productdetails/showspec/${item.product_id}/`)
        //   .then((response) => {
        //     let result = response.data.data.find((obj) => {
        //       return obj.id == item.specification_id;
        //     });

        //     Object.assign(this.editedItem, result);

        //     this.dialog = true;

        //     console.log(this.editedItem);
        //   });
      },
      downloadCSV(csv, filename){
            var csvFile;
            var downloadLink;          
            csvFile = new Blob([csv], {type: 'text/csv'});
            downloadLink = document.createElement("a");
            downloadLink.download = filename;
            downloadLink.href = window.URL.createObjectURL(csvFile);
            downloadLink.style.display = "none";
            document.body.appendChild(downloadLink);
            downloadLink.click();
        },
    
        exportTableToCSV(filename) {
        
            var csv = [];
            var rows = document.querySelectorAll(" #dataapp table tr");
            
            for(var i=0; i<rows.length; i++) {
            var row = [], cols = rows[i].querySelectorAll("td, th");
            for( var j=0; j<cols.length; j++)
                row.push(cols[j].innerText);
            csv.push(row.join(","));
            } 
            
            this.downloadCSV(csv.join("\n"), filename);
        },

      // This Link will be Change for link change
      print() {
        let routeData = this.$router.resolve({
          name: `site/get_productreport_pdf/`,
          // name: `http://127.0.0.1:8000/site/get_productreport_pdf/`
        });
        routeData.href = axios.defaults.baseURL + `site/get_productreport_pdf/`;
        // routeData.href = `http://127.0.0.1:8000/site/get_productreport_pdf/`;
        window.open(routeData.href, "_blank");
      },

      saveEdit() {
        let editedSpecification = {
          color: this.editedItem.color,
          delivery_height: this.editedItem.delivery_info.height,
          delivery_inside_city_charge: this.editedItem.delivery_info
            .charge_inside,
          delivery_length: this.editedItem.delivery_info.length,
          delivery_outside_city_charge: this.editedItem.delivery_info
            .charge_outside,
          delivery_product_unit: this.editedItem.delivery_info.measument_unit,
          delivery_weight: this.editedItem.delivery_info.weight,
          delivery_width: this.editedItem.delivery_info.width,
          discount_amount: this.editedItem.discount.amount,
          discount_end_date: this.editedItem.discount.end_date,
          discount_start_date: this.editedItem.discount.start_date,
          discount_type: this.editedItem.discount.discount_type,
          point_amount: this.editedItem.point.point,
          point_end_date: this.editedItem.point.end_date,
          point_start_date: this.editedItem.point.start_date,
          price: this.editedItem.price.price,
          product_unit: this.editedItem.unit,
          purchase_price: this.editedItem.price.purchase_price,
          size: this.editedItem.size,
          warranty: this.editedItem.warranty,
          warranty_unit: this.editedItem.warranty_unit,
          weight: this.editedItem.weight,
          vat: this.editedItem.vat,
          SKU: this.editedItem.SKU,
          expire: this.editedItem.expire,
          manufacture_date: this.editedItem.manufacture_date,
        };
        axios
          .post(
            `productdetails/edit_specification/${this.editedItem.id}/`,
            editedSpecification
          )
          .then((response) => {
            // console.log("After Edit ", response.data);
            if (response.data.success) {
              // comment  Object.assign(this.contract[this.editedIndex], editedSpecification);

              this.dialog = false;
              this.text = response.data.message;
              //comment this.$refs.editSpecificationForm.reset();
              //this.text = "Specification has been uploaded successfully";
              this.color = "success";
              this.snackbar = true;
              // this.$router.go(this.$router.currentRoute);
            } else {
              //this.text = "Something went wrong !!";
              this.text = response.data.message;
              this.color = "error";
              this.snackbar = true;
            }
          })
          .catch((err) => {
            this.text = "Something went wrong !!";
            //this.text = response.data.message;
            this.color = "error";
            this.snackbar = true;
          });
      },

      add_discount(item) {
        this.addDiscountDialog = true;
        Object.assign(this.specdataobjdiscount, item);
        // console.log(this.specdataobjdiscount);
      },
      Canceldiscount() {
        this.addDiscountDialog = false;
      },
      saveDist(specdataobjdiscount) {
        let datas = {
          discount_type: this.specdataobjdiscount.discount.discount_type,
          discount_amount: this.specdataobjdiscount.discount.discount_amount,
          discount_start_date: this.specdataobjdiscount.discount
            .discount_start_date,
          discount_end_date: this.specdataobjdiscount.discount.discount_end_date,
          product_id: this.specdataobjdiscount.product_id,
        };
        //console.log("this is item", datas);

        axios
          .post(
            `site/add_discount/${this.specdataobjdiscount.specification_id}/`,
            datas
          )
          .then((response) => {
            if (response.data.success) {
              this.text = response.data.message;
              this.addDiscountDialog = false;
              this.color = "success";
              this.snackbar = true;
            } else {
              this.text = response.data.message;
              this.color = "error";
              this.snackbar = true;
            }
          });
      },
    },

    mounted() {
      this.initialize();
    },
  };
</script>



<style >
  .v-data-table > .v-data-table__wrapper > table > tbody > tr > th,
  .v-data-table > .v-data-table__wrapper > table > thead > tr > th,
  .v-data-table > .v-data-table__wrapper > table > tfoot > tr > th {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    font-size: 1.2rem;
    height: 48px;
  }
</style>
