var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"mb-16",attrs:{"fluid":""}},[_c('v-card',{attrs:{"height":"100%"}},[_c('v-row',[_c('v-card-text',{staticStyle:{"background":"white","height":"100%"}},[_c('v-row',{staticClass:"ml-7",staticStyle:{"width":"96%"}},[_c('v-col',[[_c('v-toolbar',{staticStyle:{"background":"#f5f5f5"},attrs:{"elevation":"2"}},[_c('v-toolbar-title',{staticClass:"mt-2 mb-2 ml-1"},[_vm._v(" Product Purchase Report ")]),_c('v-spacer'),_c('div',{staticClass:"pr-4 mt-5 d-flex "},[_c('div',{staticClass:"search g"},[_c('v-text-field',{attrs:{"label":"Search by product id","filled":"","rounded":"","dense":"","hide-details":""},model:{value:(_vm.search_key),callback:function ($$v) {_vm.search_key=$$v},expression:"search_key"}})],1),_c('div',{staticClass:"icon-search pr-4 pb-5 pt-2"},[_c('v-btn',{staticStyle:{"background":"red"},attrs:{"icon":""}},[_c('v-icon',{on:{"click":_vm.searchHandle}},[_vm._v("mdi-magnify")])],1)],1)]),_c('div',[_c('v-btn',{staticClass:"ml-10",attrs:{"text":""},on:{"click":_vm.print}},[_c('v-icon',{attrs:{"large":"","color":"black"}},[_vm._v("mdi-printer ")])],1)],1),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){return _vm.exportTableToCSV('daily_report.csv')}}},[_c('v-icon',{staticClass:"pr-5",attrs:{"large":"","color":"black"}},[_vm._v("mdi-download ")])],1)],1),_c('div',{staticClass:"mt-4",attrs:{"id":"dataapp"}},[_c('v-data-table',{staticClass:"elevation-1",staticStyle:{"background":"#f5f5f5 elevation-1"},attrs:{"headers":_vm.SellerHeader,"items":_vm.specifications,"search":_vm.searchseller,"server-items-length":_vm.totalItems1,"loading":_vm.loading,"loading-text":"Loading... Please wait","hide-default-footer":"","disable-pagination":""},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"shaped":"","icon":"","color":"green","dark":""},on:{"click":function($event){return _vm.viewSpecification(item)}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-pencil")])],1)]}},{key:"item.discount",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"shaped":"","text":"","color":"green"},on:{"click":function($event){return _vm.add_discount(item)}}},[_vm._v(" Discount ")])]}}])})],1),_c('div',{staticClass:"d-flex justify-content-center mt-5"},[_c('div',{staticClass:"col-4"},[_vm._v(" Total "+_vm._s(_vm.totalItems1)+" records ")]),_c('div',{staticClass:"col-4"},[_c('v-select',{attrs:{"dense":"","outlined":"","hide-details":"","label":"Items per page","items":_vm.pageSizes1},on:{"change":_vm.handlePageSizeChange},model:{value:(_vm.perPage1),callback:function ($$v) {_vm.perPage1=$$v},expression:"perPage1"}})],1),_c('div',{staticClass:"col-4"},[_c('v-pagination',{attrs:{"length":_vm.totalPages1,"total-visible":"7"},on:{"input":_vm.handlePageChange},model:{value:(_vm.currentPage1),callback:function ($$v) {_vm.currentPage1=$$v},expression:"currentPage1"}})],1)])]],2)],1)],1)],1)],1),_c('div',{staticClass:"text-center"},[_c('v-dialog',{attrs:{"width":"500"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return undefined}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5 grey lighten-2"},[_vm._v(" Privacy Policy ")]),_c('v-card-text',[_vm._v(" Your request is accecpted.We will send you a Pdf file in your mail. Thanks ")]),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){_vm.dialog = false}}},[_vm._v(" Close ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }